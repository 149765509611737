




import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import Deferrable from '@/components/Deferrable.vue'

@Component({
  components: {
    Deferrable
  }
})

export default class NumberIncrement extends Vue {
  private runningCount = 0

  private incrementBy = 1;

  @Prop({ required: true })
  private countTo!: number;

  get numberOfChars (): number {
    return this.countTo.toString().length
  }

  mounted (): void {
    this.incrementNumber()
  }

  @Prop({ required: true })
  private millsToComplete!: number;

  @Watch('countTo')
  incrementNumber (): void {
    let timeToIncrement = this.millsToComplete / this.countTo
    while (timeToIncrement < 10) {
      timeToIncrement *= 2
      this.incrementBy *= 2
    }
    const timer = setInterval(() => {
      if (this.runningCount < this.countTo) {
        this.runningCount += this.incrementBy
      } else {
        clearInterval(timer)
      }
    }, timeToIncrement)
  }
}

