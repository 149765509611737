






















import { GetCategoryModel } from '@/app_code/models/GetCategoryModel'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class UnitCategoryCard extends Vue {
  @Prop()
  private readonly category!: GetCategoryModel

  private isHovered = false

  mounted (): void {
    this.$el.addEventListener('mouseenter', this.handleMouseEnter)
    this.$el.addEventListener('mouseleave', this.handleMouseLeave)
  }

  beforeUnmount (): void {
    this.$el.removeEventListener('mouseenter', this.handleMouseEnter)
    this.$el.removeEventListener('mouseleave', this.handleMouseLeave)
  }

  handleMouseEnter (): void {
    this.isHovered = true

    this.$el.addEventListener('mousemove', this.handleMouseMove)
  }

  handleMouseLeave (): void {
    this.isHovered = false

    this.$el.removeEventListener('mousemove', this.handleMouseMove)

    const elem = this.$el as HTMLAnchorElement

    elem.style.transform = 'rotateY(0deg) rotateX(0deg)'

    const bg = this.$refs.bg as HTMLImageElement

    bg.style.transform = 'rotateY(0deg) rotateX(0deg)'
  }

  handleMouseMove (evt: Event): void {
    const e = evt as MouseEvent

    const elem = this.$el as HTMLAnchorElement

    const angleX =
      ((e.offsetX - elem.clientWidth * 0.5) / elem.clientWidth) * 10
    const angleY =
      ((e.offsetY - elem.clientHeight * 0.5) / elem.clientHeight) * -10

    elem.style.transform = `rotateY(${angleX}deg) rotateX(${angleY}deg)`

    const bg = this.$refs.bg as HTMLImageElement

    bg.style.transform = `rotateY(${angleX * 1.2}deg) rotateX(${angleY *
      1.2}deg)`
  }

  private categoryMap: Record<string, string> = {
    ACARA: 'acara.jpg',
    'Accredited Course': 'accredited-course.jpg',
    'Agriculture, Horticulture, Conservation, Land Management':
      'agriculture.jpg',
    'Animal Care and Management': 'animal-care-management.jpg',
    'Automotive Retail, Service and Repair Training Package': 'mechanic.jpg',
    Business: 'business.jpg',
    'Community Services': 'community-services.jpg',
    Construction: 'construction.jpg',
    'CPP Property Services': 'property-services.jpg',
    'Creative Arts and Culture': 'creative-arts-culture.jpg',
    Electrotechnology: 'electrotechnology.jpg',
    'Financial Services': 'financial-services.jpg',
    'Food, Beverage and Pharmaceutical': 'food-beverage.jpg',
    'Forest and Wood Products': 'forest-wood-products.jpg',
    'Foundation Skills': 'foundation-skills.jpg',
    Furnishing: 'furnishing.jpg',
    'Hairdressing and Beauty Services': 'hairdressing.jpg',
    Health: 'health.jpg',
    'Information and Communications Technology':
      'information-communcations-technology.jpg',
    'Laboratory Operations': 'laboratory-operations.jpg',
    'Manufacturing and Engineering': 'manufacturing-engineering.jpg',
    Maritime: 'maritime.jpg',
    'Plastics, Rubber and Cablemaking': 'plastics-rubber-cablemaking.jpg',
    'Public Sector': 'public-sector.jpg',
    'Resources and Infrastructure': 'resources-infrastructure.jpg',
    'Retail Services': 'retail-services.jpg',
    'Sport, Fitness and Recreation': 'sport-fitness-recreation.jpg',
    'Tourism, Travel and Hospitality': 'tourism-travel-hospitality.jpg',
    'Training and Education': 'training-education.jpg',
    'Transmission, Distribution and Rail': 'transmission-distribution.jpg',
    Transport: 'transport.jpg',
    'Australian Meat Processing': 'australian-meat-processing.jpg',
    _default: 'default.jpg'
  }

  getCategoryImage (category: string): string {
    if (this.categoryMap[category]) return this.categoryMap[category]

    return this.categoryMap._default
  }
}
