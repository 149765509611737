
















































import GetUnitCategoryModel from '@/app_code/models/GetUnitCategoryModel'
import { ServiceManager } from '@/app_code/services'
import { View } from '@/app_code/services/meta/view'
import ServiceType from '@/app_code/services/ServiceTypes'
import UnitCategoryCard from '@/components/cards/UnitCategoryCard.vue'
import { Component, Mixins } from 'vue-property-decorator'
import { API } from '../app_code/services/api/API'
import Topic from '../components/Topic.vue'
import Loader from '../components/ui/Loader.vue'
import NumberIncrement from '@/components/NumberIncrement.vue'

@Component({
  components: {
    Topic,
    UnitCategoryCard,
    Loader,
    NumberIncrement
  }
})
export default class UnitLibrary extends Mixins(View) {
  private searchInput = ''
  private currentPage = 1
  private unitCount = 0
  private categories: GetUnitCategoryModel[] | null = null
  private trainingPackagesCount = 0

  private api = ServiceManager.Require<API>(ServiceType.API)

  mounted (): void {
    this.getCategories()
    this.getUnitCount()
  }

  getCategories (): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.api.UnitsAPI.GetCategories(true)
        .then(data => {
          this.trainingPackagesCount = data?.length ?? 0
          this.categories = data?.filter(item => item.unitCount) ?? null
          resolve(true)
        })
        .catch(reject)
    })
  }

  getUnitCount (): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.api.UnitsAPI.GetUnits(1, '', '00000000-0000-0000-000000000000')
        .then(data => {
          this.unitCount = data?.totalItems ?? 0
          resolve(true)
        })
        .catch(reject)
    })
  }
}
