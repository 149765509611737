








import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Deferrable extends Vue {
    @Prop({ default: 'fade' })
    private readonly transition!: string;

    @Prop({ default: 500 })
    private readonly delay!: number;

    @Prop({ default: 500 })
    private readonly transitionSpeed!: number;

    private mount = false;

    private debounce?: number;

    private eventListenerOpts = { passive: true };

    private checkPos () {
      clearTimeout(this.debounce)

      this.debounce = window.setTimeout(() => {
        const el = this.$el

        const rect = el.getBoundingClientRect()

        const visible = rect.top >= 0 && rect.top <= (window.innerHeight || document.documentElement.clientHeight)

        if (visible) {
          this.destroyListener()

          this.mount = true
        }
      }, this.delay)
    }

    private setupListener () {
      document.addEventListener('scroll', this.checkPos, this.eventListenerOpts)
    }

    private destroyListener () {
      document.removeEventListener('scroll', this.checkPos)
    }

    mounted (): void {
      this.setupListener()

      this.checkPos()
    }

    beforeDestroy (): void {
      this.destroyListener()
    }
}
